@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  // Lowest priority
  .link {
    @apply text-link-text hover:text-link-text-hover
    dark:text-link-text-dark dark:hover:text-link-text-dark-hover;
  }

  .input {
    @apply disabled:opacity-50 disabled:pointer-events-none p-2.5 px-4 text-sm rounded-lg border outline-none
    bg-input-bg border-border text-primary-text placeholder:text-disabled-text focus:border-primary focus:ring-primary
    dark:bg-input-bg-dark dark:text-primary-text-dark dark:placeholder:text-disabled-text-dark dark:border-border-dark
    dark:focus:border-primary-dark dark:focus:ring-primary-dark
    duration-300 ease-in-out transition-colors;
  }

  .input-small {
    @apply disabled:opacity-50 disabled:pointer-events-none p-1.5 px-2.5 text-sm rounded-md border outline-none
    bg-input-bg border-border text-primary-text placeholder:text-disabled-text focus:border-primary focus:ring-primary
    dark:bg-input-bg-dark dark:text-primary-text-dark dark:placeholder:text-disabled-text-dark dark:border-border-dark
    dark:focus:border-primary-dark dark:focus:ring-primary-dark
    duration-300 ease-in-out transition-colors;
  }

  .textarea {
    @apply disabled:opacity-50 disabled:pointer-events-none p-2.5 px-4 text-sm rounded-lg border outline-none min-h-11 max-h-64
    bg-input-bg border-border text-primary-text placeholder:text-disabled-text focus:border-primary focus:ring-primary
    dark:bg-input-bg-dark dark:text-primary-text-dark dark:placeholder:text-disabled-text-dark dark:border-border-dark
    dark:focus:border-primary-dark dark:focus:ring-primary-dark
    duration-300 ease-in-out transition-colors;
  }

  .button {
    @apply flex flex-nowrap items-center justify-center text-center gap-3 rounded-lg px-4 py-2 text-sm font-medium disabled:opacity-50 disabled:pointer-events-none;
  }

  .button-tiny {
    @apply flex flex-nowrap items-center justify-center text-center gap-1 rounded-md px-1.5 py-0 text-[9px] font-medium disabled:opacity-50 disabled:pointer-events-none;
  }

  .button-small {
    @apply flex flex-nowrap items-center justify-center text-center gap-1.5 rounded-md px-2.5 py-1.5 text-xs font-medium disabled:opacity-50 disabled:pointer-events-none;
  }

  .button-large {
    @apply flex flex-nowrap items-center justify-center text-center gap-3 rounded-lg px-6 py-2.5 text-base font-medium disabled:opacity-50 disabled:pointer-events-none;
  }

  .button-very-large {
    @apply flex flex-nowrap items-center justify-center text-center gap-3 rounded-xl px-8 py-6 text-2xl font-medium disabled:opacity-50 disabled:pointer-events-none;
  }

  .button-default {
    @apply border
    bg-white hover:bg-neutral-50 text-primary-text border-border
    dark:bg-neutral-700 dark:hover:bg-neutral-600 dark:text-primary-text-dark dark:border-border-dark
    duration-300 ease-in-out transition-all;
  }

  .button-default-outline {
    @apply border
    text-primary-text border-border hover:bg-neutral-500/5
    dark:text-primary-text-dark dark:border-border-dark dark:hover:bg-neutral-500/25
    duration-300 ease-in-out transition-all;
  }

  .button-snackbar-default-action {
    @apply text-white bg-neutral-700 hover:bg-neutral-600
    dark:text-white dark:bg-neutral-700 dark:hover:bg-neutral-600
    duration-300 ease-in-out transition-all;
  }

  .button-snackbar-success-action {
    @apply text-white bg-success hover:bg-success-hover
    dark:text-white dark:bg-success-dark dark:hover:bg-success-dark-hover
    duration-300 ease-in-out transition-all;
  }

  .button-snackbar-info-action {
    @apply text-white bg-info hover:bg-info-hover
    dark:text-white dark:bg-info-dark dark:hover:bg-info-dark-hover
    duration-300 ease-in-out transition-all;
  }

  .button-snackbar-warning-action {
    @apply text-white bg-warning hover:bg-warning-hover
    dark:text-white dark:bg-warning-dark dark:hover:bg-warning-dark-hover
    duration-300 ease-in-out transition-all;
  }

  .button-snackbar-danger-action {
    @apply text-white bg-danger hover:bg-danger-hover
    dark:text-white dark:bg-danger-dark dark:hover:bg-danger-dark-hover
    duration-300 ease-in-out transition-all;
  }

  .button-default-outline-faded {
    @apply border
    text-disabled-text border-disabled-text/20 hover:bg-black/5
    dark:text-disabled-text-dark dark:border-disabled-text-dark/20 dark:hover:bg-white/15
    duration-300 ease-in-out transition-all;
  }

  .button-primary {
    @apply bg-primary hover:bg-primary-hover text-primary-contrast-primary-text
    dark:bg-primary-dark dark:hover:bg-primary-dark-hover dark:text-primary-dark-contrast-primary-text
    duration-300 ease-in-out transition-all;
  }

  .button-secondary {
    @apply bg-secondary hover:bg-secondary-hover text-secondary-contrast-primary-text
    dark:bg-secondary-dark dark:hover:bg-secondary-dark-hover dark:text-secondary-dark-contrast-primary-text
    duration-300 ease-in-out transition-all;
  }

  .button-danger {
    @apply bg-danger hover:bg-danger-hover text-white
    dark:bg-danger-dark dark:hover:bg-danger-dark-hover dark:text-primary-text-dark
    duration-300 ease-in-out transition-all;
  }

  .button-danger-outline {
    @apply border
    border-danger text-danger hover:bg-danger-hover hover:text-white
    dark:border-danger-dark dark:text-danger-dark dark:hover:bg-danger-dark dark:hover:text-white
    duration-300 ease-in-out transition-all;
  }

  .button-blurred {
    @apply border backdrop-blur
    text-white border-white/25 bg-black/25 hover:border-white/45 hover:bg-black/45
    duration-300 ease-in-out transition-all;
  }

  .badge-blurred {
    @apply border backdrop-blur rounded-full py-0.5 px-2.5 text-xs
    text-white border-white/25 bg-black/25
    duration-300 ease-in-out transition-all;
  }

  .spinner-on-bg {
    @apply inline animate-spin
    text-primary fill-disabled-text/5
    dark:text-primary-dark dark:fill-disabled-text-dark/5;
  }

  .spinner-on-light {
    @apply inline animate-spin
    text-primary fill-disabled-text;
  }

  .spinner-on-dark {
    @apply inline animate-spin
    text-primary-dark fill-disabled-text-dark;
  }

  .spinner-on-primary {
    @apply inline animate-spin
    text-primary-contrast-primary-text fill-primary-contrast-disabled-text/25
    dark:text-primary-dark-contrast-primary-text dark:fill-primary-dark-contrast-disabled-text/25;
  }

  .spinner-on-secondary {
    @apply inline animate-spin
    text-secondary-contrast-primary-text fill-secondary-contrast-disabled-text/5
    dark:text-secondary-dark-contrast-primary-text dark:fill-secondary-dark-contrast-disabled-text/5;
  }

  .spinner-on-danger {
    @apply inline animate-spin
    text-white fill-neutral-400;
  }

  .generating-badge {
    @apply absolute right-0 bottom-0 inline-flex translate-y-1/4 translate-x-1/4 items-center justify-center rounded-full
    border-2 size-5
    border-neutral-50
    dark:border-neutral-900;
  }

  .generating-badge-initializing {
    @apply text-white bg-info dark:bg-info-dark dark:text-white;
  }

  .generating-badge-in-queue {
    @apply text-white bg-info dark:bg-info-dark dark:text-white;
  }

  .generating-badge-in-progress {
    @apply text-white bg-warning dark:bg-warning-dark dark:text-white;
  }

  .generating-badge-converting-to-svg {
    @apply text-white bg-warning dark:bg-warning-dark dark:text-white;
  }

  .generating-badge-completed {
    @apply text-white bg-success dark:bg-success-dark dark:text-white;
  }

  .generating-badge-cancelled {
    @apply text-white bg-info dark:bg-info-dark dark:text-white;
  }

  .generating-badge-failed {
    @apply text-white bg-danger dark:bg-danger-dark dark:text-white;
  }

  .generating-badge-timed-out {
    @apply text-white bg-danger dark:bg-danger-dark dark:text-white;
  }

  .counter-badge {
    @apply absolute right-0 top-0 inline-flex h-5 -translate-y-1/2 translate-x-1/2 items-center justify-center rounded-full
    border-2 p-1.5 text-[8px] font-bold
    border-neutral-50 text-white bg-neutral-300
    dark:border-neutral-900 dark:bg-neutral-700;
  }

  .counter-badge-primary {
    @apply bg-primary
    dark:bg-primary-dark;
  }

  .counter-badge-secondary {
    @apply bg-primary
    dark:bg-primary-dark;
  }

  .counter-badge-success {
    @apply bg-success
    dark:bg-success-dark;
  }

  .counter-badge-warning {
    @apply bg-warning
    dark:bg-warning-dark;
  }

  .counter-badge-danger {
    @apply bg-danger
    dark:bg-danger-dark;
  }
}

@layer components {
  // Moderate priority
}

@layer utilities {
  // Highest priority
}

// [Styling sidebar] //
// For: Firefox
* {
  scrollbar-width: thin;
  scrollbar-color: var(--secondary) var(--primary);
}

// For: Chrome, Edge, and Safari
*::-webkit-scrollbar {
  width: 18px;
}

*::-webkit-scrollbar-track {
  background: var(--primary);
  border-radius: 5px;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary);
  border-radius: 14px;
  border: 3px solid var(--primary);
}
